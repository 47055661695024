import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import "bootstrap/dist/css/bootstrap.min.css";

import { headData } from "../mock/data";
import "../style/main.scss";

export default () => {
  const { lang } = headData;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AVISO DE PRIVACIDAD</title>
        <html lang={lang || "en"} />
        <meta name="description" content="AVISO DE PRIVACIDAD" />
      </Helmet>
      <section id="hero" className="jumbotron">
        <Container>
          <Fade bottom duration={1000} delay={500} distance="30px">
            <h1 className="hero-title text-center">
              AVISO DE PRIVACIDAD DE GRUPO CONTABLE Y TECNOLÓGICO DEL SURESTE
              S.A.P.I DE C.V
            </h1>
          </Fade>
          <Fade>
            <h2>
              Fecha de &uacute;ltima actualizaci&oacute;n: 06 de julio de 2020
            </h2>
            <p>
              <br />
            </p>
            <p>
              GRUPO CONTABLE Y TECNOL&Oacute;GICO DEL SURESTE S.A.P.I DE C.V en
              adelante la &ldquo;Responsable&rdquo;, con domicilio en la oficina
              PH 08 de la Torre Magnia ubicada de la calle 15, numero 503 por 18
              y 12, Altabrisa, M&eacute;rida Yucat&aacute;n, CP 97130, emite el
              presente Aviso de Privacidad, como responsable de la
              protecci&oacute;n del uso y tratamiento de sus datos personales.
              Su informaci&oacute;n personal ser&aacute; utilizada, entre otros,
              de forma enunciativa no limitativa, para celebrar toda clase de
              actividades relacionadas con la prestaci&oacute;n de sus servicios
              mediante un Sitio Web que permite a los Usuarios tener acceso a un
              autodiagn&oacute;stico de estatus contable-fiscal, el cual se
              obtiene realizando una investigaci&oacute;n del registro contable
              &ndash;fiscal de los Usuarios en la base de datos del Servicio de
              Administraci&oacute;n Tributaria, extrayendo la informaci&oacute;n
              fiscal y analiz&aacute;ndola para arrojar un Reporte en el que el
              Usuario conozca y concientice su situaci&oacute;n fiscal con el
              objeto de mejorarla con el servicio contable digital que
              proporciona KONTER., incorporar dichos datos personales a los
              instrumentos jur&iacute;dicos que se requieran para la formalizar
              los contratos que, en su caso, sean celebrados, as&iacute; como
              los instrumentos jur&iacute;dicos accesorios a dichos contratos,
              utilizarlos en cualquier tipo de acto o diligencia de cobranza
              judicial y/o extrajudicial, y contactarle a usted para ofrecerle
              servicios, as&iacute; como para informarle sobre los cambios de
              los mismos y para otras actividades afines. Para lograr el
              cumplimiento adecuado de dichas finalidades, la Responsable
              solicitar&aacute;, recabar&aacute; y almacenar&aacute;, entre
              otros, los siguientes datos personales: a) Nombre completo, b)
              Direcci&oacute;n de correo electr&oacute;nico, c) Registro Federal
              de Contribuyentes d) Clave &Uacute;nica de Registro de la
              Poblaci&oacute;n e) Clave CIEC e informaci&oacute;n patrimonial.
            </p>
            <p>
              Si desea ejercer sus derechos de acceder, rectificar, cancelar y
              oponerse al uso, tratamiento o divulgaci&oacute;n de sus datos
              personales, o bien revocar, total o parcialmente, el
              consentimiento que para tal fin nos otorga, lo podr&aacute;
              realizar a trav&eacute;s de los procedimientos implementados. Para
              conocer dichos procedimientos, puede contactar al Departamento de
              Datos Personales de la Responsable a trav&eacute;s del correo
              electr&oacute;nico hola@konter.com.mx o visitar nuestra
              p&aacute;gina de Internet: www.konter.com.mx
            </p>
            <p>
              Su informaci&oacute;n puede ser compartida con personas
              f&iacute;sicas o morales o terceros receptores, con los cuales
              tengamos alg&uacute;n tipo de relaci&oacute;n jur&iacute;dica o de
              negocios, por ejemplo, con terceros, con sociedades subsidiarias,
              filiales o afiliadas de la Responsable, a efecto del cumplimiento
              satisfactorio de las finalidades mencionadas en este aviso.
            </p>
            <p>
              En caso de que usted no se encuentre de acuerdo con los
              t&eacute;rminos de este Aviso de Privacidad, deber&aacute;
              manifestar su oposici&oacute;n mediante el env&iacute;o de un
              correo electr&oacute;nico a la direcci&oacute;n
              hola@konter.com.mxde lo contrario, se entender&aacute; que ha
              otorgado su completo consentimiento.
            </p>
            <p>
              La Responsable le invita a revisar peri&oacute;dicamente este
              aviso para estar informado acerca de lo relativo a sus datos
              personales. Cualquier cambio al presente, podr&aacute; consultarlo
              en el domicilio de la Responsable as&iacute; como en la
              p&aacute;gina de internet de la Responsable www.konter.com.mx y
              cuando esto suceda, modificaremos la fecha de la &uacute;ltima
              actualizaci&oacute;n.
            </p>
            <p>
              La responsable cuenta con medidas de seguridad, administrativas,
              t&eacute;cnicas y f&iacute;sicas, para evitar cualquier
              da&ntilde;o, p&eacute;rdida, alteraci&oacute;n,
              destrucci&oacute;n, uso, acceso o divulgaci&oacute;n indebida o no
              actualizada de sus datos personales, de tal forma que todo lo
              anteriormente manifestado se aplicar&aacute; de la manera y en los
              t&eacute;rminos que la Responsable estime m&aacute;s convenientes
              y eficaces, en acatamiento a lo dispuesto en la Ley Federal de
              Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los
              Particulares.
            </p>
            <p>
              <br />
            </p>
            <p>DEPARTAMENTO DE DATOS PERSONALES</p>
            <p>
              GRUPO CONTABLE Y TECNOL&Oacute;GICO DEL SURESTE S.A.P.I DE C.V
            </p>
            <p>Domicilio: oficina PH 08 de la Torre Magnia</p>
            <p>ubicada de la calle 15, numero 503 por 18 y 12,</p>
            <p>Altabrisa, M&eacute;rida Yucat&aacute;n, CP 97130,</p>
            <p>Correo electr&oacute;nico: hola@konter.com.mx</p>
          </Fade>
          <Fade bottom duration={1000} delay={1000} distance="30px">
            <p className="hero-cta justify-content-center">
              <Link className="cta-btn cta-btn--hero" to="/">
                Regresar
              </Link>
            </p>
          </Fade>
        </Container>
      </section>
    </>
  );
};
